import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query NotFound {
    contentfulSiteConfig {
      ...siteConfig
    }
  }
`;

const Page: React.FunctionComponent & ILayout = () => {
  return <Template />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.NotFoundQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      pageTitle="Page Not Found"
      pathname={location.pathname}
    />
  );
};
